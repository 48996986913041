<template>
  <div class="row">
    <div class="card form-body">
      <div class="card-body">
        <nav
          class="nav nav-pills nav-justified"
          v-if="currentUser.u.is_admin == 1"
        >
          <a
            class="nav-link"
            @click="active_tab = 'fdle'"
            :class="active_tab == 'fdle' ? 'active' : ''"
            aria-current="page"
            href="#"
          >
            Florida</a
          >
          <a class="nav-link" @click="active_tab = 'spsc'"
            :class="active_tab == 'spsc' ? 'active' : ''"
            aria-current="page" href="#"> Service Codes</a>   
        </nav>
        <hr v-if="currentUser.u.is_admin == 0" />

        <!-- <v-map v-if="active_tab == 'rate'"></v-map>
            <v-sms v-if="active_tab == 'archival'"></v-sms> -->
        <v-fdle-rates v-if="active_tab == 'fdle'"></v-fdle-rates>
        <v-fingerprint-rates v-else></v-fingerprint-rates>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import { mapGetters } from "vuex";
import vFdleRates from "@/pages/backend/serviceprovider/serviceorirates/fdlerates";
import vFingerprintRates from "@/pages/backend/serviceprovider/serviceorirates/fingerprintrate";
export default {
  components: {
    vFdleRates,
    vFingerprintRates
  },
  data() {
    return {
      active_tab: "rate",
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: ""
        },
        {
          id: 2,
          title: "Service Providers",
          route: ""
        },
        {
          id: 3,
          title: "Rates",
          route: ""
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["currentUser"])
  },
  created() {
    this.breadCrumbs[0].route = "admin.location";
    this.breadCrumbs[1].route = "admin.serviceprovider";
    this.breadCrumbs[2].title = "Rates";
    this.active_tab = "fdle";

    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
  }
};
</script>
