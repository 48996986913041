
<template>
  <div class="row">
    <div class="col-md-12" style="background: white">
      <div class="card form-body" style="border:none">
        <div class="card-body">
          <legend>
            <h6>Service Code | {{ client.name }}</h6>
          </legend>

          <span v-if="serviceError" class="text-danger">{{
            serviceError
          }}</span>
          <hr />
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>
                  <!-- <input
                    type="text"
                    name=""
                    class="form-control table-form-control float-left"
                    placeholder="Search Service"
                    id=""
                  /> -->
                </th>
                <th colspan="4" class="text-center">Default</th>
                <th colspan="3" class="text-center">Custom</th>
              </tr>
              <tr>
                <!-- <th class="text-left">
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    v-model="selectAll"
                    @change="selectAllArchivals()"
                  />
                </th> -->
                <th class="text-left">Service</th>
                <th class="text-right">Cost</th>
                <th class="text-right">Tax(%)</th>
                <th class="text-right">Markup($)</th>
                <th class="text-right">Cost</th>
                <th class="text-right">Tax(%)</th>
                <th class="text-right">Markup($)</th>
              </tr>
            </thead>
            <tbody v-if="!loadingData && service_code_datas.length > 0">
              <tr v-for="(data, index) in service_code_datas" :key="index">
                <!-- <td class="text-left">
                  <input type="checkbox" name="" id="" v-model="data.checked" />
                </td> -->
                <td class="text-left">{{ data.service_name }}</td>
                <td class="text-right">{{ data.default_cost }}</td>
                <td class="text-right">{{ data.default_tax }}</td>
                <td class="text-right">{{ data.default_markup }}</td>
                <td>
                  <input
                    type="number"
                    min="0"
                    name=""
                    class="form-control table-form-control"
                    id=""
                    v-model="data.sp_default_cost"
                  />
                </td>
   
                <td>
                  <input
                    type="number"
                    min="0"
                    name=""
                    class="form-control table-form-control"
                    id=""
                    v-model="data.sp_default_tax"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    min="0"
                    name=""
                    class="form-control table-form-control"
                    id=""
                    v-model="data.sp_default_markup"
                  />
                </td>
              </tr>
            </tbody>
            <tbody v-if="loadingData">
              <tr>
                <th class="text-center" colspan="10">
                  <vDataLoader></vDataLoader>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer">
          <button
            class="btn btn-success ml-3"
            style="float: right"
            @click="update('S', 'kt_save_sp_sc_rates')"
            ref="kt_save_sp_sc_rates"
          >
            Update
          </button>

          <button
            ref="kt_save_add_another_company"
            class="btn btn-danger mr-3"
            @click="goBack()"
            style="float: right"
          >
            <i class="fa fa-arrow-left"></i> Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vDataLoader from "@/components/frontend/spinner.vue";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import {
  FETCH_SERVICECODES,
  FETCH_SERVICECODES_BY_SP,
  UPDATE_SERVICECODES_BY_SP,
} from "@/core/services/store/actions.type";
import { mapGetters } from "vuex";
export default {
  components: {
    vDataLoader
  },
  data() {
    return {
      breadCrumbs: [
      {
          id: 1,
          title: "Dashboard",
          route: "admin.dashboard"
        },
        {
          id: 2,
          title: "Service Providers",
          route: "admin.serviceprovider.index"
        },
        {
          id: 3,
          title: "Update Rate ",
          route: ""
        }
      ],
      archival_datas: [],
      service_code_datas:[],
      errors: [],
      loadingData: false,
      serviceError: "",
      selectAll: false,
      archivals: [],
      archival_rates: [],
      client: []
    };
  },
  computed: {
    ...mapGetters(["getBArchivals", "getBArchivalRateLists","getServiceCodeData"])
  },

  created() {
    this.breadCrumbs[2].title = "Edit Rate";
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    // this.getLocationRateLists();
    this.getServiceCode();
  },
  methods: {
    getServiceCode() {
      this.loadingData = true;
      this.$store
        .dispatch(FETCH_SERVICECODES)
        .then((response) => {
          this.$store
          .dispatch(FETCH_SERVICECODES_BY_SP, this.$route.params.id)
          .then((response1)=>{
            if(response1.data==[]){
              // this.service_code_datas=response.data.map((element)=>{
              //   return {...element, element.sp_default_cost:null,element.sp_default_tax:null,element.sp_default_markup:''}
              // })
              this.service_code_datas=response.data
              
            }
            else{
              this.service_code_datas=response.data
              response1.data.forEach((element2) => {
                let index=this.service_code_datas.findIndex(x=>element2.service_code==x.id)
                 if(index!=-1){
                  this.service_code_datas[index]={...this.service_code_datas[index],
                  sp_default_cost:element2.default_cost,
                  sp_default_tax:element2.default_tax,
                  sp_default_markup:element2.default_markup
                  }
                 }               
              });
            }
          })

          this.loadingData = false;
        })
        .catch(() => {
          this.loadingData = false;
        });
    },
    goBack() {
      this.$router.push({
        name: "admin.serviceprovider.index"
      });
    },
    selectAllArchivals() {
      if (this.selectAll) {
        this.archival_datas.filter(archival => {
          return (archival.checked = true);
        });
      } else {
        this.archival_datas.filter(archival => {
          return (archival.checked = false);
        });
      }
    },
    closeBtnLoad(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
      submitButton.disabled = false;
    },
    loadingButton(ref) {
      const submitButton = this.$refs[ref];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },
    getLocationRateLists() {
      this.loadingData = true;
    },
   
    update(saveType, ref) {
      console.log(saveType,ref)
      let payloadData=[]
      this.service_code_datas.forEach((element)=>{
        payloadData.push({
          "service_code_id":element.id,
          "client_id":this.$route.params.id,
          "default_cost":element.sp_default_cost??"",
          "default_tax":element.sp_default_tax??"",
          "default_markup":element.sp_default_markup??""
        })
      })
      console.log(payloadData)


        this.$store
          .dispatch(UPDATE_SERVICECODES_BY_SP, payloadData)
          .then(data => {
            this.$toastr.s(data.msg);
            this.closeBtnLoad(ref);
            // if (saveType === "S") {
            //   this.$router.push({ name: "client.location" });
            // }
          })
          .catch(err => {
            this.errors = err;
            // this.selectAll = false;
            this.serviceError = "";
            this.getServiceCode();
            this.closeBtnLoad(ref);
          });
      }
    }
  }
</script>

<style scoped>
input[type="number"] {
  -moz-appearance: textfield !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
