<template>
  <div class="row">
    <div class="col-md-12" style="background: white">
      <div class="card form-body" style="border:none">
        <div class="card-body">
          <legend>
            <h6>Florida | {{ client.name }}</h6>
          </legend>

          <!-- <span v-if="serviceError" class="text-danger">{{
            serviceError
          }}</span> -->
          <hr />
          <div
            style="display: flex; justify-content: flex-end; align-items: center;"
          >
            <!-- <div class="form-group form-inline-flex">
              <input
                type="checkbox"
                v-model="checkallmarkup"
                @change="checkMarkupAllResult_()"
              /><span class="ml-2 mr-5" style="font-weight: 600;"
                >Default Markup :
              </span>
              <input
                v-on:keyup="changetaxpercentage(index)"
                type="number"
                min="0"
                name=""
                class="form-control schedule_input"
                id=""
                v-model="default_markup"
              />

              <input
                type="checkbox"
                v-model="checkalltax"
                @change="checkTaxAllResult_()"
              />

              <span class="ml-2" style="font-weight: 600;">Default Tax: </span>
              <input
                v-on:keyup="changetaxpercentage(index)"
                type="number"
                min="0"
                name=""
                class="form-control schedule_input"
                id=""
                v-model="default_tax"
              />
            </div> -->

            <div>
              <span>
                <button
                  class="btn btn-sm btn-secondary font-weight-bolder mb-2"
                  style="float: right"
                  @click="Refresh()"
                >
                  Refresh
                </button>
              </span>
            </div>
          </div>
          <table class="table table-bordered">
            <thead style="position: sticky; top: -1rem; background: white;">
              <tr>
                <th>
                  <!-- <input
                    type="text"
                    name=""
                    class="form-control table-form-control float-left"
                    placeholder="Search Service"
                    id=""
                  /> -->
                </th>
                <th colspan="1" class="text-center">TBS Admin</th>
                <th colspan="6" class="text-center">Custom Rate</th>
              </tr>
              <tr>
                <th colspan="2"></th>

                <th colspan="1">
                  <div
                    style="display: flex; justify-content: space-between; align-items: center;"
                  >
                    <!-- <input
                      type="checkbox"
                      v-model="checkallmarkup"
                      @change="checkMarkupAllResult_()"
                    /> -->
                    <input
                      v-on:keyup="checkMarkupAllResult_()"
                      type="number"
                      placeholder="Use Default Markup"
                      min="0"
                      name=""
                      class="form-control ml-2"
                      id=""
                      v-model="set_default_markup"
                    />
                  </div>
                </th>
                <th colspan="2" class="text-center"></th>
                <th colspan="1">
                  <div
                    style="display: flex; justify-content: space-between; align-items: center;"
                  >
                    <!-- <input
                      type="checkbox"
                      v-model="checkalltax"
                      @change="checkTaxAllResult_()"
                    /> -->
                    <input
                      v-on:keyup="checkTaxAllResult_()"
                      type="number"
                      placeholder="Use Default Tax"
                      min="0"
                      name=""
                      class="form-control ml-2"
                      id=""
                      v-model="set_default_tax"
                    />
                  </div>
                </th>
              </tr>

              <tr>
                <th class="text-left">ORI</th>
                <th class="text-right">Cost($)</th>
                <th class="text-right">Default Markup($)</th>
                <th class="text-right">Markup($)</th>
                <th class="text-right">Sub Total($)</th>
                <th class="text-right">Default Tax(%)</th>
                <th class="text-right">Tax(%)</th>
                <th class="text-right">Selling Price($)</th>
              </tr>
            </thead>
            <tbody v-if="!loadingData && table_datas.length > 0">
              <tr v-for="(data, index) in table_datas" :key="index">
                <td class="text-left">{{ data.service_ori }}</td>

                <td class="text-right mt-2">
                  {{ data.our_rate }}
                </td>
                <td class="text-right mt-2">
                  {{ client.default_markup }}
                </td>

                <td class="text-right mt-2">
                  <input
                    v-on:keyup="changemarkup(index)"
                    type="number"
                    min="0"
                    name=""
                    class="form-control table-form-control"
                    id=""
                    v-model="data.markup"
                  />
                </td>
                <td class="text-right mt-2">
                  {{ data.sub_total }}
                </td>
                <td class="text-right mt-2">
                  {{ client.default_tax }}
                </td>
                <td class="text-right mt-2">
                  <input
                    v-on:keyup="changetaxpercentage(index)"
                    type="number"
                    min="0"
                    name=""
                    class="form-control table-form-control"
                    id=""
                    v-model="data.tax_percentage"
                  />
                </td>

                <td class="text-right mt-2">
                  {{ data.selling_price }}
                </td>
              </tr>
            </tbody>
            <tbody v-if="loadingData">
              <tr>
                <th class="text-center" colspan="10">
                  <vDataLoader></vDataLoader>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer">
          <button
            class="btn btn-success ml-3 mb-5"
            style="float: right"
            @click="update('S', 'kt_save_service')"
            ref="kt_save_service"
          >
            Update
          </button>

          <button
            ref="kt_save_add_another_company"
            class="btn btn-danger mr-3 mb-5"
            @click="goBack()"
            style="float: right"
          >
            <i class="fa fa-arrow-left"></i> Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vDataLoader from "@/components/frontend/spinner.vue";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import {
  FETCH_FLORIDA_SERVICEORI,
  FETCH_SP_ORI_RATE_LISTS,
  UPDATE_SP_ORI_RATE_LISTS
} from "@/core/services/store/actions.type";
import { mapGetters } from "vuex";
export default {
  components: {
    vDataLoader
  },
  data() {
    return {
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "admin.dashboard"
        },
        {
          id: 2,
          title: "Service Providers",
          route: "admin.serviceprovider.index"
        },
        {
          id: 3,
          title: "Update Rate ",
          route: ""
        }
      ],
      table_datas: [],
      errors: [],
      loadingData: false,
      serviceError: "",
      selectAll: false,
      service_ori: [],
      rates: [],
      default_markup: "",
      default_tax: "",
      client: [],
      set_default_tax: "",
      set_default_markup: "",
      checkallmarkup: false,
      checkalltax: false
    };
  },
  computed: {
    ...mapGetters(["getServiceOriData"])
  },
  // watch: {
  //   services: {
  //     handler(val) {
  //       val.data.map(service => {
  //         // console.log(rate);
  //         // var key = this.getBServices.
  //         var key = this.rates.findIndex(x => x.service.id === service.id);
  //         if (key === -1) {
  //           this.table_datas.push({
  //             id: service.id,
  //             service: service.name,
  //             rate: "",
  //             has_additional_rate: service.has_additional_rate,
  //             additional_rate: "",
  //             tax_percentage: "",
  //             commission_percentage: "",
  //             checked: false,
  //             default_rate: service.default_rate,
  //             default_additional_rate: service.additional_rate,
  //             default_tax: service.tax_percentage,
  //             default_commission: service.commission_percentage
  //           });
  //         } else {
  //           this.table_datas.push({
  //             id: service.id,
  //             service: service.name,
  //             rate: this.rates[key].rate,
  //             has_additional_rate: this.rates[key].has_additional_rate,
  //             additional_rate: this.rates[key].additional_rate,
  //             tax_percentage: this.rates[key].tax_percentage,
  //             commission_percentage: this.rates[key].commission_percentage,
  //             checked: true,
  //             default_rate: service.default_rate,
  //             default_additional_rate: service.additional_rate,
  //             default_tax: service.tax_percentage,
  //             default_commission: service.commission_percentage
  //           });
  //         }
  //       });
  //     }
  //   }
  //   // getBRateLists: {
  //   //   handler(val) {
  //   //     this.table_datas = [];
  //   //     val.map((rate) => {
  //   //       this.table_datas.push({
  //   //         id: rate.service.id,
  //   //         service: rate.service.name,
  //   //         rate: rate.rate,
  //   //         has_additional_rate: rate.has_additional_rate,
  //   //         additional_rate: rate.additional_rate,
  //   //         tax_percentage: rate.tax_percentage,
  //   //         commission_percentage: rate.commission_percentage,
  //   //         checked: true,
  //   //         default_rate: rate.service.default_rate,
  //   //         default_additional_rate: rate.service.additional_rate,
  //   //         default_tax: rate.service.tax_percentage,
  //   //         default_commission: rate.service.commission_percentage,
  //   //       });
  //   //     });
  //   //     let table_datas_ids = [];
  //   //     this.table_datas.map((t) => {
  //   //       table_datas_ids.push(t.id);
  //   //     });
  //   //     this.getBServices.data.map((service) => {

  //   //       console.log(this.table_datas,service);

  //   //       // if (this.table_datas.length > 0) {

  //   //       //   if (!table_datas_ids.includes(service.id)) {
  //   //       //     this.table_datas.push({
  //   //       //       id: service.id,
  //   //       //       service: service.name,
  //   //       //       rate: "",
  //   //       //       has_additional_rate: service.has_additional_rate,
  //   //       //       additional_rate: "",
  //   //       //       tax_percentage: "",
  //   //       //       commission_percentage: "",
  //   //       //       checked: service.checked,
  //   //       //       default_rate: service.default_rate,
  //   //       //       default_additional_rate: service.additional_rate,
  //   //       //       default_tax: service.tax_percentage,
  //   //       //       default_commission: service.commission_percentage,
  //   //       //     });
  //   //       //   }
  //   //       // } else {
  //   //       //    alert(table_datas_ids.includes(service.id));
  //   //       //   this.table_datas.push({
  //   //       //     id: service.id,
  //   //       //     service: service.name,
  //   //       //     rate: "",
  //   //       //     has_additional_rate: false,
  //   //       //     additional_rate: "",
  //   //       //     tax_percentage: "",
  //   //       //     commission_percentage: "",
  //   //       //     checked: false,
  //   //       //     default_rate: service.default_rate,
  //   //       //     default_additional_rate: service.additional_rate,
  //   //       //     default_tax: service.tax_percentage,
  //   //       //     default_commission: service.commission_percentage,
  //   //       //   });
  //   //       // }
  //   //     });
  //   //   },
  //   // },
  // },
  created() {
    this.breadCrumbs[2].title = "Edit Rate";
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    // this.getLocationRateLists();
    this.getServiceData();
  },
  methods: {
    goBack() {
      // var client_slug = this.$route.params.client_slug;
      this.$router.push({
        name: "admin.serviceprovider.index"
      });
    },
    selectAllServices() {
      if (this.selectAll) {
        this.table_datas.filter(service_ori => {
          return (service_ori.checked = true);
        });
      } else {
        this.table_datas.filter(service_ori => {
          return (service_ori.checked = false);
        });
      }
    },
    closeBtnLoad(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
      submitButton.disabled = false;
    },
    loadingButton(ref) {
      const submitButton = this.$refs[ref];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },
    getLocationRateLists() {
      this.loadingData = true;
    },
    getServiceData() {
      this.loadingData = true;
      this.table_datas = [];
      var data_ = { id: this.$route.params.id, code: "fdle" };
      this.$store
        .dispatch(FETCH_FLORIDA_SERVICEORI)
        .then(data_service => {
          //   console.log(data_service);
          this.$store
            .dispatch(FETCH_SP_ORI_RATE_LISTS, data_)
            .then(data => {
              this.rates = data.rates;
              this.client = data.client;
              this.set_default_markup = this.client.default_markup;
              this.set_default_tax = this.client.default_tax;
              if (this.client.default_markup == "") {
                this.client.default_markup = this.client.sp_default_rate;
              }
              if (this.client.default_tax == "") {
                this.client.default_tax = this.client.sp_default_tax;
              }
              this.breadCrumbs[2].title = "Rates | " + this.client.name;
              data_service.data.map(service_ori => {
                // console.log(service_ori);
                // console.log(rate);
                // var key = this.getBServices.
                var key = this.rates.findIndex(
                  x => x.service_ori.id === service_ori.id
                );

                if (key === -1) {
                  var our_rate =
                    service_ori.our_rate == ""
                      ? 0
                      : parseFloat(service_ori.our_rate);
                  var default_markup =
                    this.client.default_markup == ""
                      ? 0
                      : parseFloat(this.client.default_markup);
                  var default_tax =
                    this.client.default_tax == ""
                      ? 0
                      : parseFloat(this.client.default_tax);
                  var sub_total = our_rate + default_markup;
                  var selling_price =
                    sub_total + (sub_total * default_tax) / 100;
                  this.table_datas.push({
                    id: service_ori.id,
                    service_ori: service_ori.ori_number,
                    checked: false,
                    our_rate: service_ori.our_rate,
                    sub_total: sub_total,
                    markup: "",
                    selling_price: selling_price.toFixed(2),
                    tax_percentage: ""
                  });
                } else {
                  this.table_datas.push({
                    id: service_ori.id,
                    service_ori: service_ori.ori_number,
                    checked: true,
                    our_rate: this.rates[key].cost,
                    sub_total: this.rates[key].sub_total,
                    markup: this.rates[key].markup,
                    selling_price: this.rates[key].selling_price,
                    tax_percentage: this.rates[key].tax_percentage
                  });
                }
              });
              this.loadingData = false;
            })
            .catch(() => {
              this.loadingData = false;
            });
        })
        .catch(() => {
          this.loadingData = false;
        });
    },
    checkTaxAllResult_() {
      if (this.checkalltax) {
        if (this.set_default_tax != "") {
          this.table_datas.forEach(element => {
            var cost =
              element.our_rate != "" ? parseFloat(element.our_rate) : 0;
            var default_tax = parseFloat(this.set_default_tax);
            element.tax_percentage = parseFloat(this.set_default_tax);
            var markup =
              element.markup == ""
                ? this.client.default_markup == ""
                  ? 0
                  : parseFloat(this.client.default_markup)
                : parseFloat(element.markup);

            element.selling_price = (
              cost +
              markup +
              ((cost + markup) * default_tax) / 100
            ).toFixed(2);
          });
        } else {
          this.table_datas.forEach(element => {
            element.tax_percentage = "";
            var default_tax =
              this.client.default_tax == ""
                ? 0
                : parseFloat(this.client.default_tax);
            var markup =
              element.markup == ""
                ? this.client.default_markup == ""
                  ? 0
                  : parseFloat(this.client.default_markup)
                : parseFloat(element.markup);

            var cost =
              element.our_rate != "" ? parseFloat(element.our_rate) : 0;

            element.selling_price =
              cost + markup + ((cost + markup) * default_tax) / 100;
          });
        }
      }
    },
    checkMarkupAllResult_() {
      // console.log(this.checkallmarkup);
      if (this.checkallmarkup) {
        if (this.set_default_markup != "") {
          this.table_datas.forEach(element => {
            var cost =
              element.our_rate != "" ? parseFloat(element.our_rate) : 0;
            var default_markup = parseFloat(this.set_default_markup);
            element.markup = default_markup;
            var tax =
              element.tax_percentage == ""
                ? parseFloat(this.client.default_tax)
                : parseFloat(element.tax_percentage);
            // element.tax_percentage = default_tax;
            element.sub_total = cost + default_markup;

            element.selling_price =
              cost + default_markup + ((cost + default_markup) * tax) / 100;
          });
        } else {
          this.table_datas.forEach(element => {
            element.markup = "";
            var default_markup =
              this.client.default_markup == ""
                ? 0
                : parseFloat(this.client.default_markup);
            var tax =
              element.tax_percentage == ""
                ? this.client.default_tax == ""
                  ? 0
                  : parseFloat(this.client.default_tax)
                : parseFloat(element.tax_percentage);
            // var markup = element.markup != "" ? element.markup : 0;
            var cost =
              element.our_rate != "" ? parseFloat(element.our_rate) : 0;
            element.sub_total = cost + default_markup;

            element.selling_price = parseFloat(
              cost + default_markup + ((cost + default_markup) * tax) / 100
            ).toFixed(2);
          });
        }
      }
    },
    changemarkup(index) {
      var our_rate =
        this.table_datas[index].our_rate != ""
          ? parseFloat(this.table_datas[index].our_rate)
          : 0;
      var default_markup =
        this.client.default_markup != ""
          ? parseFloat(this.client.default_markup)
          : 0;
      var default_tax =
        this.client.default_tax != "" ? parseFloat(this.client.default_tax) : 0;
      var markup_rate =
        event.target.value == ""
          ? default_markup
          : parseFloat(event.target.value);
      var tax =
        this.table_datas[index].tax_percentage == ""
          ? default_tax
          : this.table_datas[index].tax_percentage;
      this.table_datas[index].sub_total = our_rate + markup_rate;
      this.table_datas[index].selling_price = parseFloat(
        our_rate + markup_rate + ((our_rate + markup_rate) * tax) / 100
      ).toFixed(2);
    },
    changetaxpercentage(index) {
      var default_markup =
        this.client.default_markup != ""
          ? parseFloat(this.client.default_markup)
          : 0;
      var default_tax =
        this.client.default_tax != "" ? parseFloat(this.client.default_tax) : 0;
      var our_rate =
        this.table_datas[index].our_rate != ""
          ? parseFloat(this.table_datas[index].our_rate)
          : 0;
      var markup_rate =
        this.table_datas[index].markup == ""
          ? default_markup
          : parseFloat(this.table_datas[index].markup);
      var tax =
        event.target.value == "" ? default_tax : parseFloat(event.target.value);
      this.table_datas[index].sub_total = our_rate + markup_rate;
      this.table_datas[index].selling_price = parseFloat(
        our_rate + markup_rate + ((our_rate + markup_rate) * tax) / 100
      ).toFixed(2);
    },
    Refresh() {
      this.checkallmarkup = false;
      this.checkalltax = false;

      this.table_datas = [];
      this.getServiceData();
    },
    update(saveType, ref) {
      this.errors = [];
      this.loadingButton(ref);
      let _data = {};
      _data.location_id = this.$route.params.id;
      _data.code = this.client.service_type;
      _data.default_rate = this.set_default_markup;
      _data.default_tax = this.set_default_tax;
      _data.rate_list = this.table_datas;

      this.$store
        .dispatch(UPDATE_SP_ORI_RATE_LISTS, _data)
        .then(data => {
          this.$toastr.s(data.msg);
          this.closeBtnLoad(ref);
          this.getServiceData();
        })
        .catch(err => {
          this.errors = err;
          this.selectAll = false;
          this.serviceError = "";

          this.getLocationRateLists();
          this.closeBtnLoad(ref);
        });
    }
  }
};
</script>

<style scoped>
input[type="number"] {
  -moz-appearance: textfield !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.schedule-input {
  width: 10%;
  margin-right: 2%;
}
.form-inline-flex {
  display: inline-flex;
}
</style>
